<template>
  <div class="container" data-aos="fade-up">
    <div class="section-title">
      <h2>開箱影片</h2>
      <p>美國小學上什麼？</p>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div
          class="member d-flex align-items-start"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
         
          <div class="post">
            <h4>【美國小學上什麼？】HMH Into Reading | K-6年級</h4>
            <div>
            <p v-if="!isShowMore" style="text-align: left;">
              經歷了奇幻的冒險之旅，現在我們準備好再度踏入充滿知性的閱讀世界！為了強化閱讀與寫作間的緊密連結，同時因應最新的課綱要求，美國知名教科書出版社HoughtonMifflin Harcourt推出了一套全新的閱讀系列教材-Into Reading👉🏻...
              <button class="read" id="mybtn" v-show="text.length > 50" @click="isShowMore = true"
                >read more</button>
            </p>
            <p v-else style="text-align: left;">
              經歷了奇幻的冒險之旅，現在我們準備好再度踏入充滿知性的閱讀世界！為了強化閱讀與寫作間的緊密連結，同時因應最新的課綱要求，美國知名教科書出版社HoughtonMifflin Harcourt推出了一套全新的閱讀系列教材-Into Reading👉🏻
              <br/><br/>學生課本分冊為： 1-2年級各分為五冊；3-6年級各分為兩冊課文內容以適齡程度為使用者整合了閱讀、寫作、聽力、口說、語言發展、發音語詞彙等能力應具備的知識與技巧。
              <br/><br/>同時，教材將社教情緒學習（Social-emotional Learning）納入課程的核心重點之一。除了採納豐富且多元的得獎文選外，教材規劃更有別以往地，透過各項運算數據，深入理解學生全面的學習需求，以協助教師採用更快速、更精準且更有效率的方式回應學生的學習需求。
              <button class="read" id="mybtn" @click="isShowMore = false">read less</button>
            </p>
          </div>
            <div class="embed-container">
              <iframe
                src="https://www.youtube.com/embed/P4t1U_8fKXk"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mt-4 mt-lg-0">
        <div
          class="member d-flex align-items-start"
          data-aos="zoom-in"
          data-aos-delay="200"
        >
        <div class="post">
            <h4>【美國小學上什麼？】HMH Into Math | K-8年級</h4>
            <div>
            <p v-if="!isShowMore2" style="text-align: left;">
              看完全新閱讀教材 HMH Into Reading，本周為您開箱2020全新數學教材－HMH Into Math，系列...<button class="read" id="mybtn" v-show="text2.length > 50" @click="isShowMore2 = true"
                >read more</button
              >
            </p>
            <p v-else style="text-align: left;">
              看完全新閱讀教材 HMH Into Reading，本周為您開箱2020全新數學教材－HMH Into Math，系列程度從幼兒園跨及至高中12年級，是一套強調｢概念建構｣與｢持續成長｣的全新數學系列教材！教材另融入了跨領域的學習指標－STEM、成長型思維等教育趨勢。<button class="read" id="mybtn" @click="isShowMore2 = false">read less</button>
            </p>
          </div>
            <div class="embed-container">
              <iframe
                src="https://www.youtube.com/embed/RHayHIvA6NA"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  setup() {
    // 使用ref创建响应式数据
    const isShowMore = ref(false);
    const isShowMore2 = ref(false);
    const paragraphs = ref([]);
    const text = ref(
      "經歷了奇幻的冒險之旅，現在我們準備好再度踏入充滿知性的閱讀世界！\n為了強化閱讀與寫作間的緊密連結，同時因應最新的課綱要求，美國知名教科書出版社HoughtonMifflin Harcourt推出了一套全新的閱讀系列教材-Into Reading👉🏻\n學生課本分冊為： 1-2年級各分為五冊；3-6年級各分為兩冊課文內容以適齡程度為使用者整合了閱讀、寫作、聽力、口說、語言發展、發音語詞彙等能力應具備的知識與技巧。\n同時，教材將社教情緒學習（Social-emotional Learning）納入課程的核心重點之一。除了採納豐富且多元的得獎文選外，教材規劃更有別以往地，透過各項運算數據，深入理解學生全面的學習需求，以協助教師採用更快速、更精準且更有效率的方式回應學生的學習需求。"
    );
    const text2 = ref(
      "看完全新閱讀教材 HMH Into Reading，本周為您開箱2020全新數學教材－HMH Into Math，系列程度從幼兒園跨及至高中12年級，是一套強調｢概念建構｣與｢持續成長｣的全新數學系列教材！教材另融入了跨領域的學習指標－STEM、成長型思維等教育趨勢。"
    );

    // 使用 onMounted 替代 mounted 钩子
    onMounted(() => {
      splitParagraphs();
    });

    // 将方法移到 setup 内
    const splitParagraphs = () => {
      // 使用换行符 '\n' 分割文本成段落
      paragraphs.value = text.value.split('\n');
    };

    // 返回需要在模板中使用的数据
    return {
      isShowMore,
      isShowMore2,
      text,
      text2,
      paragraphs,
    };
  },
};
</script>

<style>
.p {
  text-align: left;
}
</style>

<template>
  
<div>
<ProductTab/>
<!-- <ProductCard/> -->
</div>

</template>

<script>
// ProductListPage 
import ProductTab from '@/components/ProductTab.vue'
// import ProductCard from '@/components/ProductCard.vue'

export default {

  props: {
      IconButtonId: {
        type: String,
        required: true,
      },
    },

  components: {ProductTab},
  setup() {
    const tabs = [
      {
        Phonics: [
          {
            id: "kidguarden",
            title: "幼兒園",
          },
        ],

        Reading: [
          {
            id: "kidguarden",
            title: "幼兒園",
          },
          {
            id: "Primary",
            title: "國小",
          },
          {
            id: "highs",
            title: "國高中",
          },
        ],
      },
    ];
    return {tabs}
  }
}
</script>

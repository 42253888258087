<template>
  <div id="BookPage">
    <BookInfo/>
    <!-- <BookContent/> -->
  </div>
</template>

<script>
import BookInfo from '../components/BookInfo.vue'
// import BookContent from '../components/BookContent.vue'


export default {
  components: {
    BookInfo,
    // BookContent
  },
};
</script>

<style>
.ProductImg {
  width: 100%;
}

.infoListTiTle {
  font-weight: 600;
  font-size: 20px;
  margin: 17px;
  text-align: left;
}

.infoList {
  text-align: left;
  margin: 15px;
}

.textinfo {
  text-align: left;
  /* margin: 0 10px;
  text-align: justify; */
}

.TiTle {
  text-align: left;
  font-weight: 550;
  margin: 10px;
}
</style>

<template>
  <div>

    <!-- 麵包屑 -->
    <nav
      style="
        --bs-breadcrumb-divider: url(
          &#34;data:image/svg + xml,
          %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='currentColor'/%3E%3C/svg%3E&#34;
        );
      "
      aria-label="breadcrumb"
    >
    <ol class="breadcrumb">
        <!-- <li
          class="breadcrumb-item"
          v-for="(breadcrumb, index) in breadcrumbList"
          :key="'breadcrumb' + index"
        >
          {{
            iconButtonIdToChinese[breadcrumb]
              ? iconButtonIdToChinese[breadcrumb]
              : breadcrumb
          }}
        </li> -->
        <li class="breadcrumb-item" >
          {{ breadcrumbList != null ?iconButtonIdToChinese[breadcrumbList[0]]:'' }}
        </li>
        <li class="breadcrumb-item" >
          {{ props.book?.category}}
        </li>
        <li class="breadcrumb-item" >
          {{ props.book?.title}}
        </li>
      </ol>
    </nav>
    <!-- 麵包屑 END -->

    <!-- <div class="dropdown">
      <button
        class="btn btn-secondary dropdown-toggle"
        type="button"
        id="breadcrumbDropdown"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Dropdown
      </button>
      <ul class="dropdown-menu" aria-labelledby="breadcrumbDropdown">
        <li
          v-for="(breadcrumb, index) in breadcrumbList"
          :key="'breadcrumb' + index"
        >
          <a class="dropdown-item" href="#" @click="navigate(breadcrumb)">
            {{
              iconButtonIdToChinese[breadcrumb]
                ? iconButtonIdToChinese[breadcrumb]
                : breadcrumb
            }}
          </a>
        </li>
      </ul>
    </div> -->

  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";

export default {
  name: "BreadCrumb",
  props: ["book"],
  setup(props) {
    const route = useRoute();
    const breadcrumbList = ref(null);
    const iconButtonIdToChinese = {
      PhonicPage: "幼兒發音",
      ReadingPage: "語文閱讀",
      LanguageArtsPage: "文法寫作",
      MathPage: "數學教材",
      SciencePage: "自然科學",
      SocialPage: "社會人文",
      ApPage: "AP",
      IbPage: "IB",
      WorldLanguagePage: "其他語言",
      SWCamp: "綜合/寒暑假",
      SatTofelPage: "考試用書",
      ReferencePage: "讀本/字典",
    };

    onMounted(() => {
      breadcrumbList.value = route.fullPath.split("/").splice(1).splice(1);
    });

    return {
      breadcrumbList,
      iconButtonIdToChinese,
      props,
    };
  },
};
</script>

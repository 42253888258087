import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/Views/HomeView.vue';
import ProductsPage from '@/Views/ProductsPage.vue';
// import IconButton from '@/components/IconButton.vue';
import BookPage from '@/Views/BookPage.vue';
// import ProductTab from '@/components/ProductTab.vue'

const routes = [
  {
    path: '/',
    redirect: { name: 'Home' }
  },
  {
    path: '/index.html',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/ProductsPage/:IconButtonId',
    name: 'ProductsPage',
    component: ProductsPage,
    props: true,
  },
  {
    path: '/:IconButtonId/:BookPageId',
    name:'BookPage',
    component: BookPage,
    props: true,
  },
  //  {
  //   path: "/ProductTab",
  //   name: "ProductTabs",
  //   component: ProductTab,
  // },
  {
    path: '/ProductsPage/:IconButtonId',
    name: 'ProductsPage',
    component: ProductsPage,
    props: true,
  },
  {
    path:'/BookPage/:IconButtonId/:BookPageId',
    name:'BookPage',
    component: BookPage,
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
<template>
  <div id="BookInfo">
    <!-- ======= Books Section ======= -->

    <section id="Books" class="Books section-bg">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <div class="container-all">
            <div class="tab-content Books-block" id="v-pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="v-pills-SP"
                role="tabpanel"
                aria-labelledby="v-pills-SP-tab"
                tabindex="0"
              >
                <BreadCrumb :book="book" />

                <div class="Product">
                  <div class="container-fluid mt-2 mb-3">
                    <div class="row no-gutters" style="background-color: white">
                      <div class="col-md-5 pr-2">
                        <div class="comment-section">
                          <div
                            class="d-flex justify-content-between align-items-center"
                          ></div>
                        </div>
                      </div>

                      <!-- BookInfo -->
                      <div id="BookInfo">
                        <div
                          class="tab-content Books-block"
                          id="v-pills-tabContent"
                        >
                          <div
                            class="tab-pane fade show active"
                            id="v-pills-SP"
                            role="tabpanel"
                            aria-labelledby="v-pills-SP-tab"
                            tabindex="0"
                          >
                            <!-- 商品內容 -->
                            <div class="Product">
                              <div class="container-fluid mt-2 mb-3">
                                <div
                                  class="row no-gutters"
                                  style="background-color: white"
                                >
                                  <div class="col-md-5 pr-2">
                                    <img class="ProductImg" :src="book?.img" />

                                    <div class="comment-section">
                                      <div
                                        class="d-flex justify-content-between align-items-center"
                                      ></div>
                                    </div>
                                  </div>
                                  <!-- 書籍上方資訊 -->
                                  <div class="col-md-7">
                                    <div class="card-r">
                                      <div class="container-fluid">
                                        <div class="Info">
                                          <h3 class="infoListTiTle">
                                            書名：{{ book?.title }}
                                          </h3>
                                          <h6 class="infoList">
                                            <p>適用年級：{{ book?.grade }}</p>
                                            <p>出版社：{{ book?.publish }}</p>
                                          </h6>

                                          <hr />
                                          <div class="product-description">
                                            <div class="mt-2">
                                              <div class="TiTle">簡介</div>
                                              <p class="textinfo">
                                                {{ book?.summary }}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- END 書籍上方資訊 -->
                                  <BookContent />
                                </div>
                              </div>
                            </div>
                            <!-- END 商品內容 -->
                          </div>
                        </div>
                      </div>
                      <!-- BookInfo End-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Books Section -->
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import BookContent from "../components/BookContent.vue";
import BreadCrumb from "../components/BreadCrumb.vue";

export default {
  name: "BookInfo",
  components: { BookContent, BreadCrumb },
  setup() {
    const route = useRoute();
    const params = route.params;
    const bookPageId = params["BookPageId"];
    const book = ref();

    onMounted(async () => {
      try {
        await fetchData();
      } catch (error) {
        console.log(error);
      }
    });

    const fetchData = async () => {
      const route = useRoute();
      const params = route.params;
      const sheetID = "17aoS-ozNpc4orvuIAF2uhNGnfB7Wc3yT4fc0zP9iwVY";
      const sheetName = params.IconButtonId;
      // const range = "A1:J100";
      const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetID}/values/${sheetName}?alt=json&key=AIzaSyD21a6U3dc4wpee6BCu4D-pxnPff9QSW4w`; //?alt=json&range=${range}
      return await fetch(url)
        .then((response) => response.json())
        .then((data) => {
          console.log("data", data);
          formatArrayDataToObject(data.values || []);
        });
    };

    // const getImageUrl = (imageUrl) => {
    //   return `${process.env.BASE_URL}${imageUrl}`;
    // };

    const formatArrayDataToObject = (dataList) => {
      const objectKey = dataList[0];
      // console.log('objectKey', objectKey);
      dataList.map((item, index) => {
        // console.log(index, item);
        if (index > 0) {
          const bookObject = {};
          // 組合 書的 欄位名稱 與 欄位值
          objectKey.map((keyItem, index) => {
            if (keyItem === "img") {
              bookObject[keyItem] = `${process.env.BASE_URL}${item[index]}`;
            } else {
              bookObject[keyItem] = item[index];
            }
          });
          console.log("bookObject", bookObject);
          // 可優化項目
          if (bookPageId === bookObject.id) {
            console.log("bookObject", bookObject);
            book.value = bookObject;
            console.log("book", book);
          }
        }
      });
    };

    return {
      book,
      route,
      params,
      bookPageId,
    };
  },
};
</script>

<style>


.ProductImg {
  width: 100%;
}

.infoListTiTle {
  font-weight: 600;
  font-size: 20px;
  margin: 17px;
  text-align: left;
}

.infoList {
  text-align: left;
  margin: 15px;
}

.textinfo {
  text-align: left;
  margin: 0 10px;
  text-align: left;
}

.TiTle {
  text-align: left;
  font-weight: 550;
  margin: 10px;
}
</style>

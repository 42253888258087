  <template>
  <div v-if="state.isLoaded">
    <!-- 書籍內容 -->
    <div class="ProductContent container-fluid">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            class="nav-link active"
            id="nav-home-SP-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-home-SP"
            type="button"
            role="tab"
            aria-controls="nav-home-SP"
            aria-selected="true"
          >
            書籍介紹
          </button>
          <button
            class="nav-link"
            id="nav-explain-SP-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-explain-SP"
            type="button"
            role="tab"
            aria-controls="nav-explain-CP"
            aria-selected="false"
          >
            書籍配備
          </button>
        </div>
      </nav>

      <div class="tab-content" id="nav-tabContent">
        <!-- 書籍介紹 -->
        <div
          class="tab-pane fade show active"
          id="nav-home-SP"
          role="tabpanel"
          aria-labelledby="nav-home-SP-tab"
          tabindex="0"
        >
          <!-- <div class="TiTle">
            <p class="textinfo">{{ state.books[bookPageId][0].title }}</p>
          </div> -->
          <div v-if="state.isLoaded">
            <p
              class="textinfo"
              v-for="(text, index) in state.books[bookPageId][0].content.split(
                '\n'
              )"
              :key="'content' + index"
            >
              {{ text }}<br />
            </p>
          </div>
          <div v-else>載入中...</div>
          

          <!-- 閱讀更多 -->
          <!-- <div class="TiTle">★ 閱讀更多</div>
          <div class="RMbtnB">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              First Phonics
            </button>

            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              Phonics A
            </button>

            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              Phonics B
            </button>
          </div> -->

          <ReadMore/>

          <hr />
          <!-- 文章內文圖片的分隔線在這裡 -->

          <!-- 內文圖片 -->
          <div class="clearfix">
            <!-- 文章內文圖片放這裡 -->
            <img
              class="ProImg"
              :src="getImageUrl(state.books[bookPageId][0].img)"
              alt=""
            />

            <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit repellat placeat enim magni
              voluptatibus iusto illo nesciunt! Similique enim quae asperiores hic ratione veritatis facere, placeat
              ipsa
              maxime ab sit?</p> -->
          </div>
          <!-- END 內文圖片 -->

          <!-- v-for="paragraph in books.content.split('\n')" :key="paragraph" -->
        </div>
        <!-- END  書籍介紹 -->

        <!-- END 閱讀更多 -->

        <!-- 書籍配備 -->
        <div
          class="tab-pane fade"
          id="nav-explain-SP"
          role="tabpanel"
          aria-labelledby="nav-explain-SP-tab"
          tabindex="0"
        >
          <!-- <div class="TiTle">教師手冊</div> -->
          <p
            class="textinfo"
            v-for="(provide, index) in state.books[bookPageId][0].provide.split(
              '\n'
            )"
            :key="'provide' + index"
          >
            {{ provide }}<br />
          </p>
          <br />
          <p class="textinfo">
            其他系列連結：<a :href="state.books[bookPageId][0].Url02">{{
              state.books[bookPageId][0].UrlName02
            }}</a>
          </p>
          <p class="textinfo">
            附件：<a target="_blank" :href="state.books[bookPageId][0].Url01">{{
              state.books[bookPageId][0].UrlName01
            }}</a>
          </p>
        </div>
        <!-- END 書籍配備 -->
      </div>
    </div>
    <!--END 書籍內容 -->
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { ref, onMounted, reactive } from "vue";
import ReadMore from './ReadMore.vue';

//import { formatArrayDataToObject } from "../utils/GoogleSheet.js";

export default {
  name: "BookContent",
  components: { ReadMore },
  setup() {
    const route = useRoute();
    const bookPageId = ref(route.params["BookPageId"]);
    const iconButtonId = ref(route.params["IconButtonId"]);
    const state = reactive({
      books: {},
      isLoaded: false,
    });
    //const isLoaded = ref(false);

    const fetchData = async () => {
      const sheetID = "1GhpKcQUUceN3ZXsRsDjtPR6hGOqyxYyK3IH6pHtwpH0";
      const sheetName = iconButtonId.value;
      // const range = "A1:J100";
      const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetID}/values/${sheetName}?alt=json&key=AIzaSyD21a6U3dc4wpee6BCu4D-pxnPff9QSW4w`; //?alt=json&range=${range}
      try {
        const response = await fetch(url);
        const data = await response.json();
        console.log("data", data);
        state.isLoaded = true;
        state.books = formatArrayDataToObject(data.values);
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(() => {
      fetchData();
    });

    const getImageUrl = (imageUrl) => {
      return `${process.env.BASE_URL}${imageUrl}`;
      //return `https://raw.githubusercontent.com/Yuki10x10/UnionOcean-WEB2/gh-pages/${imageUrl}`;
      //return `http://www.union.com.tw/UnionOcean-WEB2/${imageUrl}`;
    };

    const formatArrayDataToObject = (dataList) => {
      const objectKey = dataList[0];
      let temp = {};
      dataList.map((item, index) => {
        if (index > 0) {
          const bookObject = {};
          // 組合 書的 欄位名稱 與 欄位值
          objectKey.map((keyItem, index) => {
            bookObject[keyItem] = item[index];
          });

          if (!(bookObject.id in temp)) {
            temp[bookObject.id] = [];
          }
          temp[bookObject.id].push(bookObject);
        }
      });
      return temp;
    };

    return {
      bookPageId,
      state,
      getImageUrl,
      fetchData,
      formatArrayDataToObject,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
p.textinfo {
  letter-spacing: normal;
}

.nav-tabs {
  color: white;
}

.tab-content {
  margin: 10px;
}


.RMbtnB {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;
}

.RMbtnB button {
  margin: 10px;
}

.carousel-control-prev,
.carousel-control-next {
  top: 50%;
}

@media (max-width: 2000px) {
  .ProImg {
    display: flex;
    margin: auto;
    width: 100%;
  }
}

@media (max-width: 800px) {
  .ProImg {
    display: flex;
    margin: auto;
    width: 100%;
  }
}
</style>

<template>
  <!-- ======= Icon Button ======= -->
  <div id="IconButton">
    <section id="HomeIcon" class="HomeIcon section-bg">
      <div class="container" style="display: inline-block; margin-top: 57px">
        <div class="aaa" data-aos="zoom-in">
          <h1>{{ IconButtonName }}</h1>
          <ul class="iconBt" id="BtBar">
            <li
              v-for="IconButton in data.newsdata.IconButtonList"
              :key="IconButton.id"
              class="IconButton"
              @click="setIconButtonAndNowTab(IconButton.id)"
            >
              <router-link
                :to="{
                  name: 'ProductsPage',
                  params: { IconButtonId: IconButton.link },
                }"
              >
                <img class="IconImg" :src="IconButton.Image" />
                <p>{{ IconButton.label }}</p>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
  <!-- End Icon Button -->
</template>

<script src="../api/IconButton.js"></script>

<style>
.iconBt {
  padding-left: 0%;
}
.IconButton {
  list-style-type: none;
}
/*--------------------------------------------------------------
      # HomeIcon
--------------------------------------------------------------*/
.HomeIcon {
  padding: 45px 0 5px 0;
  text-align: center;
  text-decoration: none;
}
.aaa {
  display: flex;
  justify-content: center;
}
.IconButton p {
  display: block;
  color: #404a4e;
  text-decoration: none;
  margin-bottom: 0;
}
.IconImg {
  /* max-width: 45%; */
  transition: all 0.4s ease-in-out;
  display: inline-block;
  margin: 10px 15px 10px 15px;
  /* filter: grayscale(100); */
  background-color: #00489b;
  /* background-color: #37517e; */
  border-radius: 50%;
}
.IconImg:hover {
  /* filter: grayscale(100); */
  transform: scale(1.1);
}
.IconButton:focus-within {
  filter: grayscale(100);
}
/* @media (max-width: 768px) {
  .HomeIcon img {
    max-width: 55%;
  }
} */
@media (max-width: 800px) {
  .iconBt {
    display: flex;
    flex-wrap: inherit;
    height: 140px;
    overflow-x: scroll;
  }
  
  .iconBt ::-webkit-scrollbar-track {
    background-color: #404a4e;
    border-radius: 10px;
  }
  .iconBt::-webkit-scrollbar-thumb {
    background-color: #00489b;
;
  }
  .iconBt::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}
}
@media (min-width: 800px) {
  .iconBt {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (max-width: 1500px) {
  .HomeIcon ul {
    width: 606px;
  }
}
@media (max-width: 400px) {
  .HomeIcon ul {
    width: 100%;
  }
}
</style>
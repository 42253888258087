<template>
  <div class="container" data-aos="fade-up">
    <div class="section-title">
      <h2>聯絡我們</h2>
      <p>收到訊息後，我們將盡快為您服務。</p>
    </div>

    <div class="row">
      <div class="col-lg-5 d-flex align-items-stretch">
        <div class="info">
          <div class="email">
            <i class="bi bi-envelope"></i>
            <h6>郵件:</h6>
            <p><a href="mailto:info@union.com.tw">info@union.com.tw</a></p>
          </div>

          <div class="phone">
            <i class="bi bi-phone"></i>
            <h6>電話 / 傳真:</h6>
            <p>
              <a href="tel:+886-4-23292669">04-2329-2669</a> /
              <a href="tel:+886-4-23292651">04-2329-2651</a>
            </p>
          </div>

          <div class="address">
            <i class="bi bi-geo-alt"></i>
            <h6>地址:</h6>
            <p>
              <a
                href="https://www.google.com/maps/place/%E8%81%AF%E6%B4%8B%E6%96%87%E5%8C%96%E4%BA%8B%E6%A5%AD%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8+Union+Ocean%2F+Houghton+Mifflin+Harcourt/@24.148961,120.651053,14z/data=!4m5!3m4!1s0x0:0xa8ecc37615c8b472!8m2!3d24.1489614!4d120.6510528?hl=zh-TW"
                target="_blank"
                rel="noopener noreferrer"
                >40861 台中市南屯區大墩十一街156號6F</a
              >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14562.290109740215!2d120.652682!3d24.151646!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa8ecc37615c8b472!2z6IGv5rSL5paH5YyW5LqL5qWt5pyJ6ZmQ5YWs5Y-4IFVuaW9uIE9jZWFuLyBIb3VnaHRvbiBNaWZmbGluIEhhcmNvdXJ0!5e0!3m2!1szh-TW!2sus!4v1662089255237!5m2!1szh-TW!2sus"
                width="100%"
                style="border: 0; height: 250px"
                allowfullscreen="false"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-7 mt-3 mt-lg-0">
        <div class="php-email-form align-items-stretch" style="height: 100%">
          <div class="row">
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSfTav4QOGvc97jV-FRcnqoim3vTQPu0U0vPDAzUNj-yHYECRQ/viewform?embedded=true"
              width="1024"
              height="500"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              >載入中…</iframe
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Email } from "@/assets/js/smtp"
// export default {
//     data() {
//         return {
//             name:"",
//             Email:"",
//             subject:"",
//             message:"",
//         };
//     },
//     method: {
//         sendEmail() {
//             Email.send({
//                 SecureToken:"",
//                 To:"",
//                 From:"",
//                 name: this.name,
//                 Subject: this.subject,
//                 Body: this.message,
//             }).thenen((message) => alert(message));
//         },
//     },
// };
</script>

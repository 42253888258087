<template>
  <div>
    <nav
      class="navbar navbar-expand-lg navbar-dark"
      style="
        background-color: #00489b;
        position: fixed;
        width: 100%;
        z-index: 1;
      "
    >
      <div class="container-fluid navBar">
        <a href="/index.html"
          ><img
            class="LOGOICON"
            src="../assets/img-1/UnionOceanLOGO-02-w.png"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon" style="color: white"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav">
            <div class="nav-link dropdown">
              <a
                class="dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                關於聯洋
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a class="nav-link" href="/index.html#Order-Process"
                    >訂購流程</a
                  >
                </li>
                <li>
                  <a class="nav-link" href="/index.html#faq">常見問題</a>
                </li>
              </ul>
            </div>
            <!-- <a class="nav-link scrollto" href="#GoodBook">好書推薦</a> -->
            <a class="nav-link scrollto" href="/index.html#vedio">開箱影片</a>
            <a class="nav-link scrollto" href="/index.html#download"
              >下載專區</a
            >
            <a class="nav-link scrollto" href="/index.html#contact">聯絡我們</a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<style>
@media (min-width: 1077px) {
  .navbar > .container-fluid {
    margin: 0 15%;
  }
}

.navbar-collapse {
  display: flex;
  flex-basis: auto;
  flex-direction: row-reverse;
}

.navFix {
  position: fixed;
  top: 0;
  width: 100%;
}
</style>

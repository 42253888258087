<template>
  <div class="container">
    <nav class="nav nav-pills" v-if="state.isLoaded">
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button
          v-for="(category, subCategory, index) in state.categories"
          :key="subCategory"
          class="nav-link"
          :class="{ active: index == store.nowTab} "
          :id="subCategory + '-tab'"
          data-bs-toggle="tab"
          :data-bs-target="'#nav-' + subCategory"
          type="button"
          role="tab"
          :aria-controls="'nav-' + subCategory"
          :aria-selected="index == store.nowTab ? true : false"
          @click="setNowTab(index)"
        >
          {{ subCategory }}
        </button>
      </div>
    </nav>
    <div class="tab-content tab-dis" id="nav-tabContent">
      <div
        v-for="(category, subCategory, index) in state.categories"
        :key="'nav-' + subCategory"
        class="tab-pane fade"
        :class="{ 'show active': index == store.nowTab }"
        :id="'nav-' + subCategory"
        role="tabpanel"
        aria-labelledby="'nav-'+subCategory+'-tab'"
      >
        <!-- card -->
        <div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 g-3">
          <div
            v-for="(bookObject, index) in state.categories[subCategory]"
            :key="subCategory + index"
          >
            <div class="col">
              <div class="card">
                <img
                  :src="getImageUrl(bookObject.img)"
                  class="card-img-top"
                  :alt="bookObject.id"
                />
                <div class="card-body">
                  <p class="card-title">{{ bookObject.title }}</p>
                  <p class="">{{ bookObject.grade }}</p>
                  <router-link
                    :to="{
                      name: 'BookPage',
                      params: { BookPageId: bookObject.id },
                    }"
                    class="btn btn-primary"
                    >詳細內容
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- card -->
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { reactive, onMounted } from "vue";
import { tabStore } from "../store/index.js";

export default {
  name: "ProductTab",
  setup() {
    const route = useRoute();
    const bookPageId = route.params["IconButtonId"];
    const state = reactive({
      categories: {},
      isLoaded: true,
    });

    const store = tabStore();

    onMounted(async () => {
      const sheetID = "1hHQyMKxxYZHTvqsddb4abekRYOjJzXz1h1U5y7Ov4m0";
      const sheetName = bookPageId;
      // const range = "A1:J100";
      const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetID}/values/${sheetName}?alt=json&key=AIzaSyD21a6U3dc4wpee6BCu4D-pxnPff9QSW4w`; //?alt=json&range=${range}

      await fetch(url)
        .then((response) => response.json())
        .then((data) => {
          console.log("data", data);
          // state.categories = formatArrayDataToObject(data.values);
          formatArrayDataToObject(data.values);
        });

      console.log(state);
    });
    function formatArrayDataToObject(dataList) {
      const objectKey = dataList[0];
      dataList.map((item, index) => {
        if (index > 0) {
          const bookObject = {};
          // 組合 書的 欄位名稱 與 欄位值
          objectKey.map((keyItem, index) => {
            if (keyItem === "img") {
              bookObject[keyItem] = `${item[index]}`;
            } else {
              bookObject[keyItem] = item[index];
            }
          });
          console.log("bookObject", bookObject.id);
          console.log(bookPageId);
          // 可優化項目
          // if (bookPageId === bookObject.id) {
          //   console.log("bookObject", bookObject);
          //   state.categories = bookObject;
          // }
          if (!(bookObject.subCategory in state.categories)) {
            state.categories[bookObject.subCategory] = [];
          }
          state.categories[bookObject.subCategory].push(bookObject);
        }
      });
    }
    console.log(state);
    console.log(state.categories);

    const getImageUrl = (imageUrl) => {
      return `${process.env.BASE_URL}${imageUrl}`;
      //return `https://raw.githubusercontent.com/Yuki10x10/UnionOcean-WEB2/gh-pages/${imageUrl}`;
      //return `http://www.union.com.tw/UnionOcean-WEB2/${imageUrl}`;
    };

    const setNowTab = (index) => {
      console.log("setNowTab",store.nowTab );
      store.nowTab = index;
      console.log("setNowTab",store.nowTab );
    };

    return {
      state,
      store,
      getImageUrl,
      setNowTab,
    };
  },
};
</script>

<style>

.nav-tabs {
  background-color: #0071bc;
  color:white;
}
.nav-link.active {
  background-color: #00489b !important;
}

.card-title {
height: 85px;
font-weight: bold;
}

.card-grade {
  font-size: 14px;
}

.tab-dis {
  margin: 15px 0;
}

.tab-demo {
  padding: 20px;
}

.tab-title {
  display: flex;
}

.tab-title li {
  padding: 10px;
}

.tab-title li a {
  display: block;
  padding: 10px;
  color: #333;
  background-color: #ccc;
  text-decoration: none;
}

.tab-title li.active a {
  color: #fff;
  background-color: #0071bc;
}

.tab-inner {
  padding: 10px;
  border: 1px solid #ccc;
}

@media (min-width: 1000px) {
    .card-title {
      font-size: 18px;
      line-height: 20px;
      height: 50px;
      }
}
@media (max-width: 1000px) {
    .card-title {
      font-size: 16px;
      height: 85px;
      }
}

</style>

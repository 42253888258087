<template>
  <div class="col-lg-8 col-md-12 footer-links">
    <h4>好站連結</h4>

    <div class="row row-cols-4 row-cols-md-5 row-cols-xl-6 g-3">
      <div
        v-for="(bookObject, index) in state.categories[subCategory]"
        :key="subCategory + index"
      >
        <div class="box row row-cols-2">
          <div class="col">
            <a target="_blank" :href="bookObject.url"
              ><img
                style="width: 160%;"
                :src="bookObject.img"
                :alt="bookObject.title"
              />
              <!-- <p style="color: #00489b;">{{ bookObject.title }}</p> -->
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted } from "vue";
import { tabStore } from "../store/index.js";

export default {
  name: "ProductTab",
  setup() {
    const GoodLink = "GoodLink";
    const state = reactive({
      categories: {},
      isLoaded: true,
    });

    const store = tabStore();

    onMounted(async () => {
      const sheetID = "1HuF9n9XXL-WlCwSrEAC_UKZiu5ncxG4g6mYMCemeO1s";
      const sheetName = GoodLink;
      // const range = "A1:J100";
      const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetID}/values/${sheetName}?alt=json&key=AIzaSyD21a6U3dc4wpee6BCu4D-pxnPff9QSW4w`; //?alt=json&range=${range}

      await fetch(url)
        .then((response) => response.json())
        .then((data) => {
          console.log("data", data);
          // state.categories = formatArrayDataToObject(data.values);
          formatArrayDataToObject(data.values);
        });

      console.log(state);
    });
    function formatArrayDataToObject(dataList) {
      const objectKey = dataList[0];
      dataList.map((item, index) => {
        if (index > 0) {
          const bookObject = {};
          // 組合 書的 欄位名稱 與 欄位值
          objectKey.map((keyItem, index) => {
            if (keyItem === "img") {
              bookObject[keyItem] = `${item[index]}`;
            } else {
              bookObject[keyItem] = item[index];
            }
          });
          console.log("bookObject", bookObject.id);
          // console.log(bookPageId);
          // 可優化項目
          // if (bookPageId === bookObject.id) {
          //   console.log("bookObject", bookObject);
          //   state.categories = bookObject;
          // }
          if (!(bookObject.subCategory in state.categories)) {
            state.categories[bookObject.subCategory] = [];
          }
          state.categories[bookObject.subCategory].push(bookObject);
        }
      });
    }
    console.log(state);
    console.log(state.categories);

    const getImageUrl = (imageUrl) => {
      return `${process.env.BASE_URL}${imageUrl}`;
      //return `https://raw.githubusercontent.com/Yuki10x10/UnionOcean-WEB2/gh-pages/${imageUrl}`;
      //return `http://www.union.com.tw/UnionOcean-WEB2/${imageUrl}`;
    };

    const setNowTab = (index) => {
      console.log("setNowTab", store.nowTab);
      store.nowTab = index;
      console.log("setNowTab", store.nowTab);
    };

    return {
      state,
      store,
      getImageUrl,
      setNowTab,
    };
  },
};
</script>

<style>
.box {
  justify-content: center;
  align-content: center;
}
</style>

import { createApp } from 'vue'
import { createPinia } from "pinia";
import App from './App.vue'
import router from '@/router'
// import router from '@/assets/router/index'
import "bootstrap"
import "bootstrap/dist/css/bootstrap.css"
import "jquery"
import "vue-router"
import  axios  from  'axios'
import  VueAxios  from  'vue-axios'
import "popper.js"
import {Tabs, Tab} from 'vue3-tabs-component';
import 'bootstrap-vue/dist/bootstrap-vue.css'

const pinia = createPinia();



// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})


createApp(App).use(router).use(pinia).component('tabs', Tabs).component('tab', Tab).use( VueAxios, axios, vuetify).mount('#app')

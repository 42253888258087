import { defineStore } from "pinia"


export const tabStore = defineStore('tab', {
  state: () => {
    return {
      nowTab:0,
      nowIconButtonId: '',
    }
  },
})

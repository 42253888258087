<template>
  <div class="home">
    <!-- ======= Hero Section ======= -->
    <section id="hero" class="d-flex align-items-center">
      <BanNer />
    </section>
    <!-- End Hero -->

    <!-- ======= about Section ======= -->
    <section id="about" class="about section-bg">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>關於聯洋</h2>
          <!-- <img
              style="width: 100%"
              src="../assets/img-1/CompanyDoor.jpg"
              alt="公司門口"
            /> -->
          <p class="a-content" style="text-align: left">
            聯洋文化為專業外文書籍代理商，提供國內雙語教育機構、國際學校各類美語教學資源，從幼稚園到大學程級，科目範圍涵蓋了：Reading、Math、
            Science、Social Studies、Language
            Art...等。聯洋文化專業團隊秉持著五大服務方向，隨時為您的需求而努力，陪伴您往提升優質美語教育的目標向前邁進。
          </p>
        </div>

        <div class="row">
          <div
            class="col-xl-3 col-md-6 d-flex align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div class="icon-box">
              <div class="icon"><i class="bx bxl-dribbble"></i></div>
              <h4><a href="">專業整合</a></h4>
              <p>
                聯洋網羅各大外文書商的學習資源，您可以不必再為多家廠商多次訂貨所煩惱。
              </p>
            </div>
          </div>

          <div
            class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div class="icon-box">
              <div class="icon"><i class="bx bx-file"></i></div>
              <h4><a href="">貼心規劃</a></h4>
              <p>
                聯洋文化集合業務與客服部門，以最誠懇、專業的的態度，提供您貼心的規劃服務。我們以您的權益為優先，依照您的教學需求與預算考量，建議最適合學校使用的學習資源。
              </p>
            </div>
          </div>

          <div
            class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <div class="icon-box">
              <div class="icon"><i class="bx bx-tachometer"></i></div>
              <h4><a href="">豐富資源</a></h4>
              <p>
                您不需擔心在使用產品的過程中孤立無援。聯洋文化除了推薦最適合您需求的教材外，也為您收集最新的教育訊息與資源。並定期舉辦教育講座，隨時提供教師專業諮詢與交流的園地。
              </p>
            </div>
          </div>

          <div
            class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
            data-aos="zoom-in"
            data-aos-delay="400"
          >
            <div class="icon-box">
              <div class="icon"><i class="bx bx-layer"></i></div>
              <h4><a href="">優質服務</a></h4>
              <p>
                您一通電話，聯洋全體團隊立即為您服務。業務部提供您第一手的學習資源；客服部門為您快速處理訂單；倉儲部門提供穩定庫存與迅速配貨,企劃部門為您介紹最新的商品與優惠訊息。
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End about Section -->

    <!-- ======= Order Process Section ======= -->
    <section id="Order-Process" class="Order-Process section-bg">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>訂購流程</h2>
          <div style="display: flex; justify-content: center">
            <p style="text-align: left">
              24小時傳真 <a href="tel:+886-4-23292651">04-2329-2651</a>
              <br />
              來電洽詢 <a href="tel:+886-4-23292669">04-2329-2669</a>
              <br />
              E-mail：<a href="mailto:info@union.com.tw">info@union.com.tw</a>
            </p>
          </div>
        </div>

        <div class="row">
          <div
            class="col-lg-7 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1"
          ></div>

          <div class="accordion-list">
            <ul>
              <li>
                <a
                  data-bs-toggle="collapse"
                  class="collapse"
                  data-bs-target="#accordion-list-1"
                  ><span>STEP 01</span> 選擇商品
                  <i class="bx bx-chevron-down icon-show"></i
                  ><i class="bx bx-chevron-up icon-close"></i
                ></a>
                <div
                  id="accordion-list-1"
                  class="collapse show"
                  data-bs-parent=".accordion-list"
                >
                  <p>
                    <strong>1.</strong>瀏覽本公司網站。 <br />
                    <br />
                    <strong>2.</strong>參考本公司商品目錄。 <br />
                    <br />
                    <strong>3.</strong>來電洽詢。
                  </p>
                </div>
              </li>

              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#accordion-list-2"
                  class="collapsed"
                  ><span>STEP 02</span> 洽詢報價
                  <i class="bx bx-chevron-down icon-show"></i
                  ><i class="bx bx-chevron-up icon-close"></i
                ></a>
                <div
                  id="accordion-list-2"
                  class="collapse"
                  data-bs-parent=".accordion-list"
                >
                  <p>
                    <strong>1.</strong
                    >請依上述洽詢辦法,告知本公司您所需要的品名及數量,本公司將針對您所需購買的商品作報價或給予適當的建議。
                    <br />
                    <br />
                    <strong>2.</strong
                    >別忘了提供聯絡人基本資料之姓名、學校服務單位、電話、地址等資料，以便加速處理您的報價程序。
                    <br />
                    <br />
                    <strong>3.</strong>如有大量採購，請來電專人為您服務。
                    <br />
                    <br />
                    <strong>4.</strong
                    >三天內若無接到報價單，煩請您來電查詢，謝謝。
                  </p>
                </div>
              </li>

              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#accordion-list-3"
                  class="collapsed"
                  ><span>STEP 03</span> 訂購商品
                  <i class="bx bx-chevron-down icon-show"></i
                  ><i class="bx bx-chevron-up icon-close"></i
                ></a>
                <div
                  id="accordion-list-3"
                  class="collapse"
                  data-bs-parent=".accordion-list"
                >
                  <p>
                    <strong>1.</strong
                    >本公司將以傳真或e-mail的方式，傳送訂單(報價單)請您確認。
                    <br />
                    <br />
                    <strong>2.</strong
                    >請於訂單(報價單)上簽名確認欲訂購的品名、數量、金額無誤後，將訂購之款項匯入指定帳號。
                  </p>
                </div>
              </li>

              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#accordion-list-4"
                  class="collapsed"
                  ><span>STEP 04</span> 確認訂購
                  <i class="bx bx-chevron-down icon-show"></i
                  ><i class="bx bx-chevron-up icon-close"></i
                ></a>
                <div
                  id="accordion-list-4"
                  class="collapse"
                  data-bs-parent=".accordion-list"
                >
                  <p>
                    在您付款後，請將付款資訊隨同訂購單(報價單)，傳真至(04)2329-2651，並來電確認本公司是否有收到。
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- End Order Process Section -->

    <!-- ======= Frequently Asked Questions Section ======= -->
    <section id="faq" class="faq section-bg">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>常見問題</h2>
          <!-- <p>
            Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex
            aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos
            quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
            fugiat sit in iste officiis commodi quidem hic quas.
          </p> -->
        </div>

        <div class="faq-list">
          <ul>
            <li data-aos="fade-up" data-aos-delay="100">
              <i class="bx bx-help-circle icon-help"></i>
              <a
                data-bs-toggle="collapse"
                class="collapse"
                data-bs-target="#faq-list-1"
                >1. 該如何訂購書籍／教材？
                <i class="bx bx-chevron-down icon-show"></i
                ><i class="bx bx-chevron-up icon-close"></i
              ></a>
              <div
                id="faq-list-1"
                class="collapse show"
                data-bs-parent=".faq-list"
              >
                <p>
                  可以來信至客服信箱info@union.com.tw，或來電至聯洋文化(04-2329-2669)，聯洋將有專人為您服務。
                  <br />
                  如欲詢價請提供您的email信箱、聯絡電話、完整聯絡人姓名，以便客服人員為您處理報價事宜。
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="200">
              <i class="bx bx-help-circle icon-help"></i>
              <a
                data-bs-toggle="collapse"
                data-bs-target="#faq-list-2"
                class="collapsed"
                >2. 多少書款可達免運門檻？
                <i class="bx bx-chevron-down icon-show"></i
                ><i class="bx bx-chevron-up icon-close"></i
              ></a>
              <div id="faq-list-2" class="collapse" data-bs-parent=".faq-list">
                <p>
                  書籍訂購金額單筆超過3000元整，即可享免運服務，離島和花東和偏遠地區等另計。
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="300">
              <i class="bx bx-help-circle icon-help"></i>
              <a
                data-bs-toggle="collapse"
                data-bs-target="#faq-list-3"
                class="collapsed"
                >3. 學校單位，有大批採購需求，該如何與聯洋聯繫？
                <i class="bx bx-chevron-down icon-show"></i
                ><i class="bx bx-chevron-up icon-close"></i
              ></a>
              <div id="faq-list-3" class="collapse" data-bs-parent=".faq-list">
                <p>
                  請來信至客服信箱info@union.com.tw，或來電至聯洋文化 (
                  04-2329-2669 )告知您的訂購需求，我們將會有專人為您服務。
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <i class="bx bx-help-circle icon-help"></i>
              <a
                data-bs-toggle="collapse"
                data-bs-target="#faq-list-4"
                class="collapsed"
                >4. 我是學校單位，請問有業務可以為我介紹教材嗎？
                <i class="bx bx-chevron-down icon-show"></i
                ><i class="bx bx-chevron-up icon-close"></i
              ></a>
              <div id="faq-list-4" class="collapse" data-bs-parent=".faq-list">
                <p>
                  可來信至客服信箱info@union.com.tw，或來電至聯洋文化 (
                  04-2329-2669 )。聯洋將有專員與您聯繫。
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="500">
              <i class="bx bx-help-circle icon-help"></i>
              <a
                data-bs-toggle="collapse"
                data-bs-target="#faq-list-5"
                class="collapsed"
                >5. 想購買教師手冊，或購買解答本。
                <i class="bx bx-chevron-down icon-show"></i
                ><i class="bx bx-chevron-up icon-close"></i
              ></a>
              <div id="faq-list-5" class="collapse" data-bs-parent=".faq-list">
                <p>
                  教師手冊以及解答等屬於教學資源類商品。這類商品僅能服務已使用書籍的學校單位。若需要更進一步了解請聯繫聯洋文化。
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="500">
              <i class="bx bx-help-circle icon-help"></i>
              <a
                data-bs-toggle="collapse"
                data-bs-target="#faq-list-6"
                class="collapsed"
                >6. 收到瑕疵教材，該如何更換？
                <i class="bx bx-chevron-down icon-show"></i
                ><i class="bx bx-chevron-up icon-close"></i
              ></a>
              <div id="faq-list-6" class="collapse" data-bs-parent=".faq-list">
                <p>
                  請拍照書籍封面/封底/受損部位後，來信至客服信箱info@union.com.tw，或來電至聯洋文化(
                  04-2329-2669 )告知您書籍瑕疵的情況，將有專人為您服務。
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="500">
              <i class="bx bx-help-circle icon-help"></i>
              <a
                data-bs-toggle="collapse"
                data-bs-target="#faq-list-7"
                class="collapsed"
                >7. 優惠活動專區 <i class="bx bx-chevron-down icon-show"></i
                ><i class="bx bx-chevron-up icon-close"></i
              ></a>
              <div id="faq-list-7" class="collapse" data-bs-parent=".faq-list">
                <p>
                  歡迎瀏覽聯洋臉書粉絲專頁「聯洋文化
                  進口書籍代理」，或加入臉書社團「聯洋
                  蝸牛慢慢書鋪」。在臉書頁面上，不定期有書籍促銷活動，或推薦的教材及相關資源分享。
                  <br />
                  如需要專員進一步推薦合適孩子的用書。歡迎來信至客服信箱info@union.com.tw，或來電至聯洋文化(
                  04-2329-2669 )。
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="500">
              <i class="bx bx-help-circle icon-help"></i>
              <a
                data-bs-toggle="collapse"
                data-bs-target="#faq-list-8"
                class="collapsed"
                >8. 請問可以直接到門市選購書籍嗎？
                <i class="bx bx-chevron-down icon-show"></i
                ><i class="bx bx-chevron-up icon-close"></i
              ></a>
              <div id="faq-list-8" class="collapse" data-bs-parent=".faq-list">
                <p>
                  請先來電聯繫，辦公室將安排專人為您服務。
                  <br />
                  上班時間 : 週一至週五 : 上午9：00～下午17：30
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!-- End Frequently Asked Questions Section -->

    <!-- ======= GoodBook Section ======= -->
    <!-- <section id="GoodBook" class="GoodBook">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>好書推薦</h2>
          <p>
            Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex
            aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos
            quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
            fugiat sit in iste officiis commodi quidem hic quas.
          </p>
        </div>

        <div class="row row-cols-1 row-cols-md-3 g-4">
          <div class="col">
            <div class="card h-100">
              <img src="../assets/img-1/GoodBook/ConquerGra001.jpg" class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Card title</h5>
                <p class="card-text">
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img src="../assets/img-1/GoodBook/ConquerGra001.jpg" class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Card title</h5>
                <p class="card-text">This is a short card.</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img src="../assets/img-1/GoodBook/ConquerGra001.jpg" class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Card title</h5>
                <p class="card-text">
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img src="../assets/img-1/GoodBook/ConquerGra001.jpg" class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Card title</h5>
                <p class="card-text">
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          id="carouselExampleControls"
          class="carousel slide"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="cards-wrapper">
                <div class="card">
                  <a href="#">
                    <img
                      src="../assets/img-1/GoodBook/ConquerGra001.jpg"
                      class="card-img-top"
                      alt="..."
                    />
                    <div class="card-body"></div>
                    <h5 class="card-title">Conquer Gramma 1</h5>
                  </a>
                </div>
              </div>
              <div class="card d-none d-md-block">
                <a href="#">
                  <img
                    src="../assets/img-1/GoodBook/Grammar-wb3.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <div class="card-body"></div>
                  <h5 class="card-title">Conquer Gramma Worrbook 3</h5>
                </a>
              </div>
            </div>
            <div class="card d-none d-md-block">
              <a href="#">
                <img
                  src="../assets/img-1/GoodBook/Grammar-wb5.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body"></div>
                <h5 class="card-title">Conquer Gramma Worrbook 5</h5>
              </a>
            </div>
          </div>
          <div class="card d-none d-md-block">
            <a href="#">
              <img
                src="../assets/img-1/GoodBook/Grammar-wb5.jpg"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body"></div>
              <h5 class="card-title">Conquer Gramma Worrbook 5</h5>
            </a>
          </div>
        </div>
        <div class="card d-none d-md-block">
          <a href="#">
            <img
              src="../assets/img-1/GoodBook/Grammar-wb5.jpg"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body"></div>
            <h5 class="card-title">Conquer Gramma Worrbook 5</h5>
          </a>
        </div>
      </div> -->

    <!-- <div class="carousel-item">
        <div class="cards-wrapper">
          <div class="card">
            <a href="#">
              <img
                src="../assets/img-1/GoodBook/ConquerGra002.jpg"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body"></div>
              <h5 class="card-title">Conquer Gramma 2</h5>
            </a>
          </div>
        </div>
        <div class="card d-none d-md-block">
          <img
            src="../assets/img-1/GoodBook/ConquerGra002.jpg"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
          </div>
        </div>
        <div class="card d-none d-md-block">
          <img
            src="../assets/img-1/GoodBook/ConquerGra002.jpg"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
          </div>
        </div>
        <div class="card d-none d-md-block">
          <img
            src="../assets/img-1/GoodBook/ConquerGra002.jpg"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
          </div>
        </div>
        <div class="card d-none d-md-block">
          <img
            src="../assets/img-1/GoodBook/ConquerGra002.jpg"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
          </div>
        </div>
      </div> -->

    <!-- <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>

      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </section> -->
    <!-- =======END GoodBook Section ======= -->

    <!-- ======= vedio Section ======= -->
    <section id="vedio" class="form section-bg">
      <VeDio />
    </section>
    <!-- =======End vedio Section ======= -->

    <!-- download Secction  -->
    <section id="download" class="download section-bg">
      <DownLoad />
    </section>
    <!-- End Donload Secction  -->

    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
      <ContactMe />
    </section>
    <!-- End Contact Section -->

    <!-- ======= Footer ======= -->
    <div>
      <footer id="footer">
        <div class="footer-newsletter">
          <div class="container">
            <div class="row justify-content-center">
              <!-- ======= 訂閱我們 ======= -->
              <!-- <div class="col-lg-6">
                <h4>訂閱我們</h4>
                <p>輸入您的電子郵件，快速取得最新電子型錄！</p>
                <form action="" method="post">
                  <input type="email" name="email" /><input
                    type="submit"
                    value="訂閱"
                  />
                </form>
              </div> -->
              <!-- ======= 訂閱我們 ======= -->
            </div>
          </div>
        </div>

        <div class="footer-top">
          <div class="container">
            <div class="box row">
              <div class="col-lg-4 col-md-6 footer-links">
                <h4>JOIN US !</h4>
                <p>加入我們快速得到第一手資訊</p>
                <div class="social-links mt-3">
                  <div
                    class="fb-page"
                    data-href="https://www.facebook.com/Union.Ocean.Co.Ltd/?locale=zh_TW"
                    data-tabs="timeline"
                    data-width=""
                    data-height=""
                    data-small-header="false"
                    data-adapt-container-width="true"
                    data-hide-cover="false"
                    data-show-facepile="true"
                  >
                    <blockquote
                      cite="https://www.facebook.com/Union.Ocean.Co.Ltd/?locale=zh_TW"
                      class="fb-xfbml-parse-ignore"
                    >
                      <a
                        href="https://www.facebook.com/Union.Ocean.Co.Ltd/?locale=zh_TW"
                        >聯洋文化 進口書籍代理
                      </a>
                    </blockquote>
                  </div>
                </div>
              </div>

              <GoodLink />
            </div>
          </div>
        </div>
      </footer>
    </div>
    <!-- End Footer -->
  </div>
</template>

<script>
import DownLoad from "@/components/DownLoad.vue";
import GoodLink from "../components/GoodLink.vue";
import ContactMe from "@/components/ContactMe.vue";
import VeDio from "@/components/VeDio.vue";
import BanNer from "@/components/BanNer.vue";
export default {
  components: { DownLoad, GoodLink, ContactMe, VeDio, BanNer },
};
</script>

<style>
.nav-pills {
  margin-bottom: 30px;
}

.box {
  justify-content: center;
  align-content: center;
}
</style>

<template>
  <div class="container" data-aos="fade-up">
    <div class="section-title">
      <h2>下載專區</h2>
      <p>音檔下載</p>
    </div>

    <ul class="nav nav-pills" id="pills-tab" role="tablist">
      <li class="nav nav-item" role="presentation">
        <button
          v-for="(category, subCategory, index) in state.categories"
          :key="subCategory"
          class="nav-link"
          :class="{ active: index == store.nowTab }"
          :id="'pills-' + subCategory + '-tab'"
          data-bs-toggle="pill"
          :data-bs-target="'#pills-' + subCategory"
          type="button"
          role="tab"
          :aria-controls="'pills-' + subCategory"
          :aria-selected="index == store.nowTab ? true : false"
          @click="setNowTab(index)"
        >
          {{ subCategory }}
        </button>
      </li>
    </ul>

    <div style="margin-top: 30px" class="tab-content" id="pills-tabContent">
      <div
        v-for="(category, subCategory, index) in state.categories"
        :key="'pills-' + subCategory"
        class="tab-pane fade"
        :class="{ 'show active': index == store.nowTab }"
        :id="'pills-' + subCategory"
        role="tabpanel"
        :aria-labelledby="'pills-' + subCategory + '-tab'"
        tabindex="0"
      >
        <div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 g-3">
          <div
            v-for="(bookObject, index) in state.categories[subCategory]"
            :key="subCategory + index"
          >
            <div class="col">
              <div class="card">
                <!-- Button trigger modal -->
                <a data-bs-toggle="modal" :data-bs-target="'#' + bookObject.id">
                  <img
                    :src="getImageUrl(bookObject.img)"
                    class="card-img-top"
                    :alt="bookObject.id"
                  />
                </a>

                <div class="card-body" style="height: 50px">
                  <p class="card-title">{{ bookObject.title }}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal -->
          <div
            v-for="(bookObject, index) in state.categories[subCategory]"
            :key="subCategory + index"
            class="modal fade"
            :id="bookObject.id"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            :aria-labelledby="bookObject.id + 'Label'"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" :id="bookObject.id + 'Label'">
                    {{ bookObject.title }}
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">{{ bookObject.content }}</div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    關閉
                  </button>
                  <button type="button" class="btn btn-primary"><a
                :href="bookObject.download"
                target="_blank"
                class="btn-get-started scrollto"
                >下載</a
              ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted } from "vue";
import { tabStore } from "../store/index.js";

export default {
  name: "ProductTab",
  setup() {
    const DownLoad = "DownLoad";
    const state = reactive({
      categories: {},
      isLoaded: true,
    });

    const store = tabStore();

    onMounted(async () => {
      const sheetID = "1_X1ZF1bRRuvhG7xY-Fm_GLXwNDtXxKFqf1oEe4nEIN0";
      const sheetName = DownLoad;
      // const range = "A1:J100";
      const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetID}/values/${sheetName}?alt=json&key=AIzaSyD21a6U3dc4wpee6BCu4D-pxnPff9QSW4w`; //?alt=json&range=${range}

      await fetch(url)
        .then((response) => response.json())
        .then((data) => {
          console.log("data", data);
          // state.categories = formatArrayDataToObject(data.values);
          formatArrayDataToObject(data.values);
        });

      console.log(state);
    });
    function formatArrayDataToObject(dataList) {
      const objectKey = dataList[0];
      dataList.map((item, index) => {
        if (index > 0) {
          const bookObject = {};
          // 組合 書的 欄位名稱 與 欄位值
          objectKey.map((keyItem, index) => {
            if (keyItem === "img") {
              bookObject[keyItem] = `${item[index]}`;
            } else {
              bookObject[keyItem] = item[index];
            }
          });
          console.log("bookObject", bookObject.id);
          // console.log(bookPageId);
          // 可優化項目
          // if (bookPageId === bookObject.id) {
          //   console.log("bookObject", bookObject);
          //   state.categories = bookObject;
          // }
          if (!(bookObject.subCategory in state.categories)) {
            state.categories[bookObject.subCategory] = [];
          }
          state.categories[bookObject.subCategory].push(bookObject);
        }
      });
    }
    console.log(state);
    console.log(state.categories);

    const getImageUrl = (imageUrl) => {
      return `${process.env.BASE_URL}${imageUrl}`;
      //return `https://raw.githubusercontent.com/Yuki10x10/UnionOcean-WEB2/gh-pages/${imageUrl}`;
      //return `http://www.union.com.tw/UnionOcean-WEB2/${imageUrl}`;
    };

    const setNowTab = (index) => {
      console.log("setNowTab", store.nowTab);
      store.nowTab = index;
      console.log("setNowTab", store.nowTab);
    };

    return {
      state,
      store,
      getImageUrl,
      setNowTab,
    };
  },
};
</script>
